import { useContext, useEffect, useMemo } from "react";
import styled from "styled-components";
import { ArtworkTile } from "../components/ArtworkTile";
import { useMeta } from "../hooks/meta";
import service from "../service";
import { globalContext } from "../store";
import { GlobalAction } from "../store/reducer";

const ArtworkList = styled.div`
    margin: var(--space-xl) 0;
    padding-top: var(--space-xxl);
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
`;
const StyledArtworkTile = styled(ArtworkTile)`
    flex: none;
`;

const breakpointSm = 576;
const breakpointMd = 768;
const breakpointLg = 992;
const breakpointXl = 1200;
const scrollbarBuffer = 20;

const colConfig = [
    { breakpoint: breakpointSm, col: 2 },
    { breakpoint: breakpointMd, col: 2 },
    { breakpoint: breakpointLg, col: 3 },
    { breakpoint: breakpointXl, col: 4 },
];
const gap = 10;
/**
 * https://stackoverflow.com/a/40723269/3127828
 * We can't use css variable in media query
 */
const ArtworkListPage = styled.div`
    .container {
        // overwrite styles from flexboxgrid2
        width: auto;
        padding: 0;
    }
    @media (max-width: ${breakpointSm - 1}px) {
        // one column
        .container {
            width: 100%;
            ${ArtworkList} {
                padding: 0 var(--space-md);
                ${StyledArtworkTile} {
                    width: 100%;
                    padding-top: 100%;
                }
            }
        }
    }
    ${colConfig.map((config, index) => {
        return `@media (min-width: ${config.breakpoint}px)${
            index !== colConfig.length - 1 ? ` and (max-width: ${colConfig[index + 1].breakpoint - 1}px)` : ""
        } {
        .container {
            width: ${config.breakpoint - scrollbarBuffer - 2 * gap}px;
            ${ArtworkList} {
                gap: ${gap}px;
                ${StyledArtworkTile} {
                    width: ${(config.breakpoint - scrollbarBuffer - gap * (1 + config.col)) / config.col}px;
                    height: ${(config.breakpoint - scrollbarBuffer - gap * (1 + config.col)) / config.col}px;
                }
            }
        }
    }`;
    })}
`;

export function Artworks() {
    const {
        dispatch: globalDispatch,
        globalState: { artworks },
    } = useContext(globalContext);
    useEffect(() => {
        service.getArtworks().then((artworks) => {
            globalDispatch({ type: GlobalAction.LOAD_ARTWORKS, payload: artworks });
        });
    }, []);

    useMeta({
        title: "Artworks",
        description: "My artwork collections",
        author: "Zhenglai Liu",
    });

    return (
        <ArtworkListPage>
            <div className="container">
                <ArtworkList>
                    {artworks.map((artwork) => (
                        <StyledArtworkTile artwork={artwork} key={artwork._id} />
                    ))}
                </ArtworkList>
            </div>
        </ArtworkListPage>
    );
}
