import React, { useContext, useState } from "react";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { globalContext } from "../store";
import { PostTag } from "../types";
import { SearchParams } from "../store/types";
import { TagPill } from "../components/TagPill";

const PostFilterContainer = styled.div`
    position: sticky;
    top: 3em;
    align-self: flex-start; // To make it sticky, must prevent the height from being stretched.
    z-index: 999;
    color: var(--color-secondary-text);
    background-color: var(--color-page-background);
    .btn {
        color: var(--color-secondary-text);
    }
`;

const PostFilterSection = styled.div`
    padding: var(--space-md) var(--space-sm);
    z-index: var(--z-index-tag-list);
    .side-section-title {
        margin-bottom: var(--space-md);
    }
`;

const SortContainer = styled.div`
    display: table;
    width: 100%;
    max-width: 15em;
    .btn {
        text-align: left;
        padding: var(--space-xs);
    }
    .sort-row {
        display: table-row;
        .sort-title,
        .sort-body {
            display: table-cell;
            white-space: nowrap;
            padding: var(--space-sm) 0;

            select {
                width: 100%;
            }
        }
        .sort-title {
            text-align: right;
            padding-right: var(--space-sm);
            width: 1px;
        }
    }
`;

const LightButton = styled.button`
    font-family: inherit;
    outline: 0;
    transition: box-shadow var(--anim-duration-short) ease-in-out;
    &:focus {
        box-shadow: 0 0 0 3px var(--focus-box-shadow-color);
    }

    display: inline-block;
    cursor: pointer;
    appearance: none;
    border: 0;
    background-color: transparent;
    padding: var(--space-sm) var(--space-md);
`;

const ToggleButton = styled(LightButton)`
    display: block;
    width: 100%;
`;

const InputSearch = styled.input.attrs(() => ({ type: "search" }))`
    padding: var(--space-sm);
    border: 1px solid var(--input-border-color);
    border-radius: var(--default-border-radius);
    outline: 0;

    &:focus {
        border-color: var(--input-focus-border-color);
        box-shadow: 0 0 0 var(--space-xs) var(--input-focus-box-shadow-color);
    }
`;

const PostSearch = styled(InputSearch)`
    width: 100%;
`;

const FilterPane = styled.div`
    @media only screen and (max-width: 767px) {
        display: none;
        margin-top: var(--space-sm);
        &.active {
            display: block;
        }
        border-bottom: solid 1px var(--color-border);
    }

    @media only screen and (min-width: 768px) {
        margin-top: var(--space-xl);
    }
`;

const FilterButtonRow = styled.div`
    text-align: right;
    margin-top: var(--space-sm);

    @media only screen and (min-width: 768px) {
        display: none;
    }
`;

const sortByOptions = [
    {
        type: "createdAt",
        text: "Created At",
    },
    { type: "view", text: "View" },
    { type: "like", text: "Like" },
];

export interface PostFilterProps {
    searchParams: SearchParams;
    onSearchParamsChange: (searchParams: SearchParams) => void;
}

export function PostFilter({ onSearchParamsChange, searchParams }: PostFilterProps) {
    const sortOrder = searchParams.sortOrder;
    const sortKey = Object.keys(sortOrder)[0];
    const order = sortOrder[sortKey];
    const searchText = searchParams.searchText;
    const [isSideBarOpen, setIsSideBarOpen] = useState(false);
    const orderIcon = order === 1 ? "long-arrow-alt-up" : "long-arrow-alt-down";
    const orderText = order === 1 ? "Asc." : "Desc.";
    const { globalState } = useContext(globalContext);
    const tags = globalState.tags;

    function toggleFilter() {
        setIsSideBarOpen((val) => !val);
    }

    function toggleTag(tag: PostTag, e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        const newTagId = tag._id;
        const index = searchParams.tagIds.indexOf(newTagId);
        let tagIds = [...searchParams.tagIds];
        if (e.metaKey) {
            // if ctrl is pressed, we do multiselect
            if (index === -1) {
                tagIds = [...tagIds, newTagId];
            } else {
                tagIds.splice(index, 1);
            }
        } else {
            // otherwise, only one tag can be selected at a time
            if (index === -1 || (index !== -1 && tagIds.length > 1)) {
                tagIds = [newTagId];
            } else {
                tagIds = [];
            }
        }

        onSearchParamsChange({ ...searchParams, tagIds });
    }

    function toggleOrder() {
        onSearchParamsChange({ ...searchParams, sortOrder: { [sortKey]: -order } });
    }

    function changeSortKey(e: React.ChangeEvent<HTMLSelectElement>) {
        onSearchParamsChange({ ...searchParams, sortOrder: { [e.target.value]: order } });
    }

    return (
        <PostFilterContainer className="col-md-3 col-sm-12 col-xs-12">
            <FilterButtonRow>
                <LightButton className="btn btn-light filter-button" onClick={toggleFilter}>
                    <FontAwesomeIcon icon="filter" />
                    &nbsp;{isSideBarOpen && "Hide"}&nbsp;Filters
                </LightButton>
            </FilterButtonRow>
            <FilterPane className={cx(isSideBarOpen && "active")}>
                <PostFilterSection>
                    <PostSearch
                        value={searchText}
                        placeholder="Search"
                        onChange={(e) => onSearchParamsChange({ ...searchParams, searchText: e.target.value })}
                    />
                </PostFilterSection>
                <PostFilterSection>
                    <div className="side-section-title">
                        <FontAwesomeIcon icon="tag"></FontAwesomeIcon>&nbsp;Tags
                    </div>
                    <div className="side-section-content tag-container">
                        {tags.map((tag) => (
                            <TagPill
                                key={tag._id}
                                onClick={(e) => toggleTag(tag, e)}
                                className={cx({ active: searchParams.tagIds.includes(tag._id) })}
                            >
                                {tag.name}
                            </TagPill>
                        ))}
                    </div>
                </PostFilterSection>
                <PostFilterSection>
                    <div className="side-section-title">
                        <FontAwesomeIcon icon="sort-amount-up"></FontAwesomeIcon>&nbsp;Sort
                    </div>
                    <SortContainer className="side-section-content ">
                        <div className="sort-row flex-horizontal">
                            <span className="sort-title">Sort By</span>
                            <span className="sort-body">
                                <select value={sortKey} onChange={changeSortKey}>
                                    {sortByOptions.map((option) => (
                                        <option key={option.type} value={option.type}>
                                            {option.text}
                                        </option>
                                    ))}
                                </select>
                            </span>
                        </div>
                        <div className="sort-row flex-horizontal">
                            <span className="sort-title">Order</span>
                            <span className="sort-body">
                                <ToggleButton className="btn btn-light btn-block" onClick={toggleOrder}>
                                    {orderText}&nbsp;
                                    <FontAwesomeIcon icon={orderIcon}></FontAwesomeIcon>
                                </ToggleButton>
                            </span>
                        </div>
                    </SortContainer>
                </PostFilterSection>
            </FilterPane>
        </PostFilterContainer>
    );
}
