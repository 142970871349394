import marked from "marked";
import hljs from "highlight.js";
import moment from "moment";
import { HashTable, Post } from "./types";

export function formatPost(value = "") {
    return { __html: marked(value) }; // not using { sanitize: true } option because sometimes post content might have HTML
}
export function highlightPost(node: HTMLElement) {
    let targets = node.querySelectorAll("pre > code");
    targets.forEach((target) => {
        hljs.highlightElement(target as HTMLElement);
    });
}

export function getScrollTop() {
    return window.pageYOffset || window.scrollY || window.document.documentElement.scrollTop || 0;
}

export function getLikeActionKey(type: string, id: string) {
    return `like-${type}-${id}`;
}

export function getViewActionKey(type: string, id: string) {
    return `view-${type}-${id}`;
}

export function isAlive(key: string) {
    let entry = localStorage.getJson(key);
    return entry && entry.time && moment() < moment(entry.time).add(6, "h");
}

export function trimContent(content: string) {
    if (!content) return "";
    const maxLength = 100;
    return content.substring(0, maxLength) + (content.length > maxLength ? "..." : "");
}

export function forOwn<T>(obj: HashTable<T>, iteratee: (val: T, key: string) => void) {
    Object.keys(obj).forEach((key) => {
        iteratee(obj[key], key);
    });
}

export function getImageUrl(url: string): string {
    return process.env.NODE_ENV === "development" ? "https://localhost:3000" + url.replace("/public", "") : url;
}

export function formatPostDate(date: Date) {
    return moment(date).format("MMM D, YYYY");
}

export function insertText(text: string) {
    document.execCommand("insertText", false, text);
}

export function wrapTextHotKey(textarea: HTMLTextAreaElement, symbol: string) {
    const len = symbol.length;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const selectedText = textarea.value.substring(start, end);
    if (textarea.value.substr(start - len, len) === symbol && textarea.value.substr(end, len) === symbol) {
        textarea.setSelectionRange(start - len, end + len);
        insertText(selectedText);
        textarea.setSelectionRange(start - len, end - len);
    } else {
        insertText(`${symbol}${selectedText}${symbol}`);
        textarea.setSelectionRange(start + len, end + len);
    }
}

export function findPost(posts: Post[], postId: string) {
    if (!postId) return null;
    return posts.find((p) => p._id === postId) || null;
}

export enum RoutePath {
    LiveEdit = "/live-edit",
}
