import { ActionType, GlobalState } from "./types";
import { initialState, loadToken } from "./index";
import { Post } from "../types";
import base64url from "base64url";

export enum GlobalAction {
    SET_VISIT_COUNT = "SET_VISIT_COUNT",
    LOAD_ARTWORKS = "LOAD_ARTWORKS",
    SEARCH_PARAMS_UPDATED = "SEARCH_PARAMS_UPDATED",
    FETCHING_POSTS = "FETCHING_POSTS",
    APPEND_POSTS = "APPEND_POSTS",
    UPDATE_TOKEN = "UPDATE_TOKEN",
    SET_POSTS = "SET_POSTS",
    SET_TAGS = "SET_TAGS",
    UPDATE_POST = "UPDATE_POST",
    DELETE_POST = "DELETE_POST",
    PREPEND_POST = "PREPEND_POST",
}

const Reducer = (state: GlobalState, action: ActionType): any => {
    switch (action.type) {
        case "SET_USER":
            return {
                ...state,
                loggedUser: action.payload,
            };
        case "AUTHENTICATE_USER":
            return {
                ...state,
                isUserAuthenticated: action.payload,
            };
        case "SET_PERSISTENCE":
            return {
                ...state,
                persistenceType: action.payload,
            };
        case "APPEND_POSTS":
            if (!action.payload.length) {
                return {
                    ...state,
                    hasMorePosts: false,
                };
            }
            return {
                ...state,
                pageIndex: state.pageIndex + 1,
                posts: state.posts.concat(action.payload),
            };
        case "RESET_POSTS":
            return {
                ...state,
                posts: [],
                pageIndex: 0,
                hasMorePosts: true,
            };
        case GlobalAction.SET_TAGS:
            return {
                ...state,
                tags: action.payload,
            };
        case "VIEW": {
            const fieldName = `${action.payload.type}s`;
            return {
                ...state,
                [fieldName]: state[fieldName].map((item: Post) => {
                    if (item._id === action.payload.id) {
                        item.view++;
                    }
                    return item;
                }),
            };
        }
        case GlobalAction.SET_VISIT_COUNT: {
            return {
                ...state,
                visitCount: action.payload,
            };
        }
        case GlobalAction.LOAD_ARTWORKS: {
            return {
                ...state,
                artworks: action.payload,
            };
        }
        case GlobalAction.SEARCH_PARAMS_UPDATED:
            return {
                ...state,
                editingFilter: true,
                pageIndex: -1,
                searchParams: action.payload,
                hasMorePosts: true,
                posts: [] as Post[],
            };
        case GlobalAction.FETCHING_POSTS:
            return {
                ...state,
                editingFilter: false,
                loading: true,
                pageIndex: action.payload,
            };
        case GlobalAction.APPEND_POSTS: {
            if (!action.payload.length) {
                return {
                    ...state,
                    loading: false,
                    hasMorePosts: false,
                };
            }
            return {
                ...state,
                loading: false,
                posts: state.posts.concat(action.payload),
            };
        }
        case GlobalAction.SET_POSTS: {
            return {
                ...state,
                posts: action.payload,
            };
        }
        case GlobalAction.UPDATE_POST: {
            const posts = state.posts.slice();
            posts.splice(
                state.posts.findIndex((p) => p._id === action.payload._id),
                1,
                action.payload,
            );
            return {
                ...state,
                posts,
            };
        }
        case GlobalAction.UPDATE_TOKEN: {
            localStorage.setJson("token", action.payload);
            return {
                ...state,
                user: loadToken(),
            };
        }
        case GlobalAction.DELETE_POST: {
            const posts = state.posts.slice();
            posts.splice(
                state.posts.findIndex((p) => p._id === action.payload),
                1,
            );
            return {
                ...state,
                posts,
            };
        }
        case GlobalAction.PREPEND_POST: {
            const posts = state.posts.slice();
            posts.unshift(action.payload);
            console.log(action.payload);
            return {
                ...state,
                posts,
            };
        }
        case "PURGE_STATE":
            return initialState;
        default:
            return state;
    }
};

export default Reducer;
