import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useImmer } from "use-immer";
import { ArtworkMeta } from "../components/ArtworkMeta";
import { ArtworkTile } from "../components/ArtworkTile";
import { LikeButton } from "../components/LikeButton";
import { Meta } from "../components/Meta";
import { MetaWrapper } from "../components/MetaWrapper";
import { useMeta } from "../hooks/meta";
import service from "../service";
import { globalContext } from "../store";
import { Artwork, HashTable } from "../types";
import { getImageUrl, getLikeActionKey, isAlive, trimContent } from "../utils";

const ArtworkWrapper = styled.div`
    padding: var(--space-md);
    .title {
        font-size: 1.5em;
        margin: var(--space-md) 0;
    }
    .meta {
        margin: var(--space-md) 0;
        font-size: 0.8em;
        color: var(--color-secondary-text);
    }
    .description {
        color: var(--color-secondary-text);
    }
    .image-list {
        margin: var(--space-md) 0;
        img {
            width: 100%;
            object-fit: cover;
            margin: var(--space-md) 0;
        }
    }
`;

const type = "artwork";
export function ArtworkPage() {
    const { artworkId } = useParams<{ artworkId: string }>();
    const [artwork, setArtwork] = useImmer<Artwork | null>(null);
    useEffect(() => {
        if (artworkId) {
            service.getArtwork(artworkId).then(setArtwork);
        }
    }, [artworkId]);
    const {
        dispatch: globalDispatch,
        globalState: { artworks },
    } = useContext(globalContext);

    const like = () => {
        if (liked || !artwork) return;
        setArtwork((draft) => {
            draft && draft.like++;
        });
        service.like(type, artwork._id);
    };
    const likeKey = useMemo(() => (artwork ? getLikeActionKey(type, artwork._id) : ""), [artwork]);
    const liked = isAlive(likeKey);
    const getMeta = useCallback((): HashTable<string> => {
        if (!artwork) {
            return {
                title: "Artwork",
                author: "Zhenglai Liu",
            };
        }
        let description = trimContent(artwork.description);
        return {
            title: artwork.title,
            description: description,
            author: "Zhenglai Liu",
        };
    }, [artwork]);
    useMeta(getMeta);

    if (!artwork) {
        return null;
    }
    return (
        <div className="artwork-page page">
            <div className="container">
                <ArtworkWrapper>
                    <div className="title">{artwork.title}</div>
                    <ArtworkMeta artwork={artwork} />
                    <div className="description">{artwork.description}</div>
                    <div className="image-list">
                        {artwork.images.map((image, index) => (
                            <img src={getImageUrl(image.path)} className="image-item" key={image.path} />
                        ))}
                    </div>
                    <LikeButton liked={liked} like={like} item={artwork} />
                </ArtworkWrapper>
            </div>
        </div>
    );
}
