import { Link } from "react-router-dom";
import { PostMeta } from "./PostMeta";
import { Post } from "../types";
import styled from "styled-components";

// .post-card {
//     .post-title {
//       transition: color 0.2s ease-in-out;
//     }

//     .post-read-more {
//       color: @color-brand;
//       text-decoration: underline;

//       &:hover {
//         text-decoration: none;
//       }
//     }
//   }

export const PostCardContainer = styled.div`
    padding: var(--space-xl) 0;
    display: block;
    position: relative;
    text-decoration: none;
    color: var(--text-color);
`;

const PostTitle = styled(Link)`
    font-weight: bold;
    font-size: 1.3em;
    word-break: break-word;
    cursor: pointer;
    text-decoration: none;
    color: var(--text-color);
`;

export interface PostCardProps {
    post: Post;
}

export function PostCard({ post }: PostCardProps) {
    return (
        <PostCardContainer>
            <PostTitle className="post-title" to={`/post/${post._id}`}>
                {post.title}
            </PostTitle>
            <PostMeta post={post}></PostMeta>
        </PostCardContainer>
    );
}
