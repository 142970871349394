import { Link, useMatch } from "react-router-dom";
import styled from "styled-components";

const Header = styled.div`
    height: 3em;
    flex: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background-image: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.8));
    @media only screen and (max-width: 767px) {
        background-color: var(--color-page-background);
    }
`;

const Container = styled.div`
    height: 100%;
`;

const Nav = styled.div`
    height: 100%;
    float: right;
    display: flex;
`;

const NavItemText = styled.div`
    height: 100%;
    display: inline-flex;
    align-items: center;
`;

const NavItemLink = styled(Link)`
    color: var(--text-color);
    text-decoration: none;
    cursor: pointer;
    transition: background-color var(--anim-duration-short) ease-in-out;
    padding: 0 var(--space-md);
    height: 100%;
    display: inline-flex;
    align-items: center;

    position: relative;

    &:hover {
        background-color: var(--color-light-grey);
    }

    &.active {
        ${NavItemText} {
            border-bottom: solid 2px #007cdc;
        }
    }
`;

const SiteLogo = styled(NavItemLink)`
    float: left;
`;

const Avatar = styled.img`
    border-radius: 100%;
    width: 2em;
    height: 2em;
    display: inline-block;
`;

function NavItem({ to, children }: any) {
    const match = useMatch({
        path: to,
    });
    return (
        <NavItemLink to={to} className={match ? "active" : ""}>
            <NavItemText>{children}</NavItemText>
        </NavItemLink>
    );
}

export function SiteHeader() {
    return (
        <Header>
            <Container className="container">
                <SiteLogo to="/">
                    <Avatar src="/android-chrome-192x192.png" />
                </SiteLogo>
                <Nav>
                    <NavItem to="/posts">Posts</NavItem>
                    <NavItem to="/artworks">Artworks</NavItem>
                </Nav>
            </Container>
        </Header>
    );
}
