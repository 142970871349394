import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useImmer } from "use-immer";
import { MarkedPostContent } from "../components/MarkedPostContent";
import { PostEditor } from "../components/PostEditor";
import service from "../service";
import { globalContext } from "../store";
import { Post } from "../types";

const StyledLiveEditPage = styled.div`
    border-top: solid 1px var(--color-light-grey);
    .splitter {
        width: var(--space-xs);
        background-color: var(--color-border);
    }
    .preview-column {
        background-color: #fff;
        overflow: auto;

        .preview-title {
            font-size: 2em;
            padding: 0.5em 1em;
            word-break: break-word;
        }

        .preview-content {
            padding: 1em;
        }
    }
`;

export function LiveEditPage() {
    const { postId } = useParams<{ postId: string }>();
    // TODO: I should use a single global post instance. Globally store postIds order and posts as mapping from postId to postObject
    const [post, setPost] = useImmer<Post | null>(null);
    useEffect(() => {
        if (postId) {
            service.getPost(postId).then(setPost);
        }
    }, [postId]);
    const onChangePost = (newPost: Post) => {
        setPost(newPost);
    };
    const onSavePost = () => {
        return service.updatePost(post as Post);
    };
    if (!post) {
        return null;
    }
    return (
        <StyledLiveEditPage className="page flex-horizontal">
            <div className="post-editor-column flex-grow">
                <PostEditor post={post} onChangePost={onChangePost} onSavePost={onSavePost} />
            </div>
            <div className="splitter"></div>
            <div className="preview-column flex-grow">
                <div className="preview" v-if="post">
                    <div className="preview-title">{post.title}</div>
                    <MarkedPostContent post={post} className="preview-content" />
                </div>
            </div>
        </StyledLiveEditPage>
    );
}
