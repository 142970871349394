import React, { useState } from "react";
import { useNavigate, useMatch } from "react-router-dom";
import styled from "styled-components";
import service from "../service";
import { RequestStatus } from "../store/types";
import { Post } from "../types";
import { insertText, RoutePath, wrapTextHotKey } from "../utils";
import { EditTagModal } from "./EditTagModal";

const PostEditorWrapper = styled.div`
    height: 100%;
    overflow: hidden;
    .title-row {
        position: relative;
        .save-status {
            position: absolute;
            right: var(--space-sm);
            top: var(--space-xs);
            font-size: 0.8em;
        }

        input[type="text"].post-title {
            font-size: 2em;
            padding: var(--space-sm) var(--space-md);
            padding-right: var(--space-xxl);
            border: 0;
            border-radius: 0;
            appearance: none;
            box-shadow: none;
            width: 100%;
        }
    }

    .action-bar {
        background-color: var(--color-border);
        .btn-action {
            border: 0;
            background-color: transparent;
            padding: var(--space-sm) var(--space-md);
            box-shadow: none;
            &:disabled {
                cursor: default;
            }
            &:not(:disabled):hover {
                background-color: var(--color-grey);
                color: #fff;
            }
        }
    }

    .post-edit-area {
        position: relative;
        .upload-image-cover {
            @extend .flex-hv-center;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);
            color: #fff;
            cursor: wait;
        }

        textarea.post-content {
            padding: var(--space-md);
            appearance: none;
            border: 0;
            resize: none;
            overflow: auto;
            box-shadow: none;
            width: 100%;
            height: 100%;
        }
    }
`;

export interface PostEditorProps {
    post: Post;
    onChangePost: (newPost: Post) => void;
    onSavePost: () => Promise<any>;
}

export function PostEditor({ post, onChangePost, onSavePost }: PostEditorProps) {
    const isInLiveEditPage = useMatch(RoutePath.LiveEdit);
    const [isEditTagModalOpen, setIsEditTagModalOpen] = useState(false);
    const [togglingPublished, setTogglingPublished] = useState(false);
    const [uploadImageStatus, setUploadImageStatus] = useState(RequestStatus.None);
    const [saving, setSaving] = useState(false);
    const navigate = useNavigate();
    function savePost() {
        setSaving(true);
        onSavePost().then(() => setSaving(false));
    }
    function viewPost() {
        navigate(`/post/${post._id}`);
    }
    function liveEditPost() {
        navigate(`${RoutePath.LiveEdit}/${post._id}`);
    }
    // BUG: published post still shows up in the posts page.
    function togglePublish() {
        if (togglingPublished) return;
        setTogglingPublished(true);
        service
            .togglePublish(post)
            .then(() => {
                onChangePost({ ...post, published: !post.published });
            })
            .finally(() => setTogglingPublished(false));
    }
    function changeTitle(newTitle: string) {
        onChangePost({ ...post, title: newTitle });
    }
    function changeContent(newContent: string) {
        onChangePost({ ...post, content: newContent });
    }
    function onPaste(e: React.ClipboardEvent<HTMLTextAreaElement>) {
        for (let i = 0; i < e.clipboardData.items.length; ++i) {
            let item = e.clipboardData.items[i];
            if (item.kind === "file" && item.type.startsWith("image/")) {
                setUploadImageStatus(RequestStatus.Pending);
                e.preventDefault();
                const textarea = e.target as HTMLTextAreaElement;
                textarea.disabled = true;
                let file = item.getAsFile();
                let name = "";
                const html = e.clipboardData.getData("text/html");
                if (html) {
                    const matched = html.match(/alt="(?<altText>.*?)"/);
                    if (matched && matched.groups) {
                        name = matched.groups.altText;
                    }
                } else {
                    name = e.clipboardData.getData("text");
                }

                if (name && file) {
                    // rename file
                    file = new File([file], name, {
                        type: file.type,
                    });
                }

                service.uploadImage(file).then((uploaded) => {
                    const paste = `![${name}](${window.location.origin}${uploaded.path})`;
                    const start = textarea.selectionStart + 2;
                    textarea.disabled = false;
                    textarea.focus();
                    insertText(paste);
                    textarea.setSelectionRange(start, start + name.length);
                    setUploadImageStatus(RequestStatus.Success);
                });
            }
        }
    }
    function onPostContentKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement>) {
        const textarea = e.target as HTMLTextAreaElement;
        if ((e.ctrlKey || e.metaKey) && e.key === "k") {
            const start = textarea.selectionStart;
            const end = textarea.selectionEnd;
            const selectedText = textarea.value.substr(start, end - start);
            insertText(`[${selectedText}](url)`);
            if (selectedText) {
                textarea.setSelectionRange(end + 3, end + 6);
            } else {
                textarea.setSelectionRange(start + 1, end + 1);
            }
        } else if ((e.ctrlKey || e.metaKey) && e.key === "b") {
            wrapTextHotKey(textarea, "**");
        } else if ((e.ctrlKey || e.metaKey) && e.key === "i") {
            wrapTextHotKey(textarea, "_");
        }
    }
    const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if ((e.metaKey || e.ctrlKey) && e.key === "s") {
            e.preventDefault();
            savePost();
        }
    };
    if (!post) {
        return null;
    }
    return (
        <PostEditorWrapper className="flex-vertical" onKeyDown={onKeyDown}>
            <div className="title-row">
                <div className="save-status">{saving ? "Saving" : "Saved"}</div>
                <input
                    type="text"
                    className="post-title"
                    value={post.title}
                    placeholder="Write your post title here..."
                    onChange={(e) => changeTitle(e.target.value)}
                />
            </div>
            <div className="action-bar">
                <button className="btn btn-action btn-save" onClick={savePost}>
                    Save
                </button>
                <button className="btn btn-action btn-view" onClick={viewPost}>
                    View
                </button>
                {!isInLiveEditPage && (
                    <button className="btn btn-action btn-live-edit" onClick={liveEditPost}>
                        LiveEdit
                    </button>
                )}
                <button className="btn btn-action btn-publish" onClick={togglePublish} disabled={togglingPublished}>
                    {togglingPublished ? (
                        <i className="loading-spinner"></i>
                    ) : (
                        <span>{post.published ? "Unpublish" : "Publish"}</span>
                    )}
                </button>
                <button className="btn btn-action btn-add-tag" onClick={() => setIsEditTagModalOpen(true)}>
                    Edit Tags
                </button>
                <EditTagModal
                    onCancel={() => setIsEditTagModalOpen(false)}
                    onPostTagsChanged={(newTags) => {
                        onChangePost({ ...post, tags: newTags });
                    }}
                    postId={post._id}
                    postTags={post.tags}
                    isOpen={isEditTagModalOpen}
                />
                {/* commit="commitTagChange" */}
            </div>
            <div className="flex-auto post-edit-area">
                <textarea
                    className="post-content"
                    value={post.content ?? ""}
                    placeholder="Write your post here..."
                    onKeyDown={onPostContentKeyDown}
                    onPaste={onPaste}
                    onChange={(e) => changeContent(e.target.value)}
                ></textarea>
                {uploadImageStatus === RequestStatus.Pending && (
                    <div className="upload-image-cover">Uploading image...</div>
                )}
            </div>
        </PostEditorWrapper>
    );
}
