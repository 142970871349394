import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { PostMeta } from "../components/PostMeta";
import { LikeButton } from "../components/LikeButton";
import service from "../service";
import { globalContext } from "../store";
import { HashTable, Post } from "../types";
import { getLikeActionKey, getScrollTop, getViewActionKey, isAlive, trimContent } from "../utils";
import { useMeta } from "../hooks/meta";
import { useImmer } from "use-immer";
import { MarkedPostContent } from "../components/MarkedPostContent";

const PostPageElement = styled.div`
    margin-top: var(--space-xl);
    padding: var(--space-xxl) 0 var(--space-xxl);
`;

const PostElement = styled.article`
    position: relative;
    z-index: 1;
    padding: var(--space-md);

    .post-title {
        font-weight: bold;
        font-size: 1.3em;
        word-break: break-word;
    }
    .post-header {
        padding-bottom: var(--space-md);
        .post-created-at {
            margin-top: var(--space-md);
            font-size: 0.8em;
            color: var(--color-grey);
        }
    }

    .post-content {
        padding-top: var(--space-md);
    }
`;

const BackToTopButton = styled.button`
    position: fixed;
    right: var(--space-xxl);
    bottom: var(--space-xxl);
    background-color: rgba(255, 255, 255, 0.8);
    width: 3em;
    height: 3em;
    z-index: var(--float-button-z-index);
    border-radius: var(--default-border-radius);
    box-shadow: 0 0 var(--space-xs) rgba(0, 0, 0, 0.1);
    border: 0;
    @media (max-width: $breakpoint-md) {
        right: var(--space-md);
        bottom: var(--space-md);
    }
`;

const type = "post";
export function PostPage() {
    const { dispatch } = useContext(globalContext);
    const { postId } = useParams<{ postId: string }>();
    const [post, setPost] = useImmer<Post | null>(null);
    useEffect(() => {
        if (postId) {
            service.getPost(postId).then(setPost);
        }
    }, [postId]);

    useEffect(() => {
        if (!post || !postId) return;
        const viewKey = getViewActionKey(type, postId);
        if (isAlive(viewKey)) return;
        dispatch({ type: "VIEW", payload: { type, id: postId } });
        service.view(type, postId);
    }, [post, dispatch, postId]);

    const [showBackToTop, setShowBackToTop] = useState(false);
    const [isGoingBackToTop, setIsGoingBackToTop] = useState(false);
    useScrollPosition(() => {
        setShowBackToTop(!isGoingBackToTop && getScrollTop() > window.document.documentElement.clientHeight * 0.5);
    }, []);

    const likeKey = useMemo(() => (post ? getLikeActionKey(type, post._id) : ""), [post]);
    const liked = isAlive(likeKey);

    const getMeta = useCallback((): HashTable<string> => {
        if (!post) {
            return {
                title: "Post",
                author: "Zhenglai Liu",
            };
        }
        let description = trimContent(post.content);
        return {
            title: post.title,
            description: description,
            author: "Zhenglai Liu",
        };
    }, [post]);

    useMeta(getMeta);

    function backTop() {
        if (isGoingBackToTop) return;
        setIsGoingBackToTop(true);

        let el = document.documentElement.scrollTop ? document.documentElement : document.body;
        let top = el.scrollTop;

        let timer = setInterval(() => {
            top -= Math.abs(top * 0.3);
            if (top <= 1) {
                top = 0;
                clearInterval(timer);
                setIsGoingBackToTop(false);
            }
            el.scrollTop = top;
        }, 20);
    }
    function like() {
        if (liked || !post) return;
        setPost((draft) => {
            draft && draft.like++;
        });
        service.like(type, post._id);
    }
    return (
        <PostPageElement className="page">
            {post && (
                <div className="container">
                    {showBackToTop && (
                        <BackToTopButton className="popover" onClick={backTop}>
                            <FontAwesomeIcon icon="chevron-up"></FontAwesomeIcon>
                        </BackToTopButton>
                    )}
                    <PostElement>
                        <div className="post-header">
                            <div className="post-title">{post.title}</div>
                            <PostMeta post={post}></PostMeta>
                        </div>
                        <MarkedPostContent post={post} />
                    </PostElement>
                    <LikeButton liked={liked} like={like} item={post} />
                </div>
            )}
        </PostPageElement>
    );
}
