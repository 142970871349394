import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useImmer } from "use-immer";
import service from "../service";
import { Artwork } from "../types";
import cx from "classnames";
import { Image } from "../types";
import { getImageUrl } from "../utils";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragEndEvent,
    UniqueIdentifier,
} from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, rectSortingStrategy } from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
const StyledArtworkEditor = styled.div`
    .body {
        padding-top: var(--space-md);
    }
    .editor-row {
        .title {
            font-size: 0.8em;
            color: var(--color-secondary-text);
            padding: var(--space-xs) 0;
        }
    }
    .editor-row + .editor-row {
        margin-top: var(--space-md);
    }
    input[type="text"],
    textarea {
        width: 100%;
    }
    textarea {
        min-height: 10em;
    }
    .draggable-container {
        &:not(.dragging) {
            .draggable-item-container:hover {
                .set-cover-button {
                    transform: translateY(0);
                }
            }
        }
        &.dragging {
            .set-cover-button,
            .cover-text,
            .delete-button {
                opacity: 0;
            }
            .draggable-item-container {
                transition: none;
            }
        }
    }
    .draggable-item-container {
        display: inline-block;
        position: relative;
        transition: transform var(--anim-duration-short) ease-in-out;
        &.active {
            z-index: 999;
            .image-container {
                box-shadow: 0 0 var(--space-md) rgba(0, 0, 0, 0.4);
                transform: scale(1.05);
            }
        }
    }
    .image-container {
        position: relative;
        width: 12em;
        height: 12em;
        overflow: hidden;
        transition: box-shadow var(--anim-duration-short) ease-in-out, transform var(--anim-duration-short) ease-in-out;
        .preview-image {
            width: 100%;
            height: 100%;
            object-fit: cover;

            &.local {
                opacity: 0.5;
            }
        }
        .set-cover-button,
        .cover-text,
        .delete-button {
            position: absolute;
            left: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.3);
            border: 0;
            color: #fff;
            border-radius: 0;
            text-align: center;
            transition: opacity var(--anim-duration-short) ease-in-out;
        }
        .set-cover-button,
        .cover-text {
            width: 100%;
            padding: var(--space-sm) var(--space-md);
        }

        .set-cover-button {
            transform: translateY(100%);
            transition: transform var(--anim-duration-short) ease-in-out;
        }
        .delete-button {
            left: auto;
            right: 0;
            top: 0;
            bottom: auto;
        }
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.3);
    }
    .draggable-container .image-container {
        cursor: move;
    }
`;

interface ArtworkImageSortItemProps {
    image: Image;
    id: UniqueIdentifier;
    index: number;
    artwork: Partial<Artwork>;
    onDeleteImage: (index: number) => void;
    onSetAsCover: (index: number) => void;
}
const ArtworkImageSortItem = ({
    image,
    index,
    id,
    artwork,
    onDeleteImage,
    onSetAsCover,
}: ArtworkImageSortItemProps) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div className={cx("draggable-item-container", { active: isDragging })} ref={setNodeRef} style={style}>
            <div className={cx("image-container")}>
                <img
                    className={cx("preview-image", { local: image.imageData })}
                    src={getImageUrl(image.path) || image.imageData}
                    {...listeners}
                    {...attributes}
                />
                <button className="btn btn-default delete-button btn-icon-only" onClick={() => onDeleteImage(index)}>
                    <FontAwesomeIcon icon="times" />
                </button>
                {index === artwork.cover ? (
                    <div className="cover-text">Cover</div>
                ) : (
                    <button className="btn btn-default set-cover-button" onClick={() => onSetAsCover(index)}>
                        Set as Cover
                    </button>
                )}
            </div>
        </div>
    );
};
const defaultArtwork: Artwork = {
    title: "",
    description: "",
    cover: 0,
    images: [],
} as any;

export function ArtworkEditorPage() {
    const { artworkId } = useParams<{ artworkId: string }>();
    const [artwork, setArtwork] = useImmer<Artwork>({ ...defaultArtwork });
    const [dragging, setDragging] = useState(false);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );
    useEffect(() => {
        if (artworkId) {
            service.getArtwork(artworkId).then(setArtwork);
        } else {
            setArtwork({ ...defaultArtwork });
        }
    }, [artworkId]);
    function uploadImages(e: React.ChangeEvent<HTMLInputElement>) {
        Array.from(e.target.files as FileList).forEach((file) => {
            const index = artwork.images.length;
            const newImages = [...artwork.images];
            newImages.push({
                file: file,
                imageData: null,
                path: "",
            } as any);
            let reader = new FileReader();
            reader.onload = (e) => {
                newImages[index].imageData = (e.target as FileReader).result;
            };
            reader.readAsDataURL(file);
            service.uploadImage(file).then((uploadedImage) => {
                delete newImages[index].imageData;
                delete (newImages[index] as any).file;
                newImages[index] = Object.assign({}, newImages[index], uploadedImage);
                setArtwork({
                    ...artwork,
                    images: newImages,
                });
            });
        });
    }

    function save() {
        service.saveArtwork(artwork).then(
            () => {
                alert("Saved Successfully!");
            },
            (err) => {
                alert(`Failed to save!\nError: ${err.response.data.err.message}`);
            },
        );
    }
    function changeTitle(newTitle: string) {
        setArtwork({ ...artwork, title: newTitle } as Artwork);
    }
    function changeDescription(newDescription: string) {
        setArtwork({ ...artwork, description: newDescription } as Artwork);
    }
    function deleteImage(index: number) {
        const newImages = artwork.images.slice(0);
        newImages.splice(index, 1);
        let newCover = artwork.cover;
        if (index === artwork.cover) {
            newCover = 0;
        } else if (index < artwork.cover) {
            newCover--;
        }
        setArtwork({
            ...artwork,
            images: newImages,
            cover: newCover,
        });
    }
    function setAsCover(index: number) {
        setArtwork({
            ...artwork,
            cover: index,
        });
    }

    function onDrageStart() {
        setDragging(true);
    }

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;

        if (active.id !== over?.id) {
            const newArtwork = { ...artwork };
            const from = newArtwork.images.findIndex((image) => image.path === active.id);
            const to = newArtwork.images.findIndex((image) => image.path === over?.id);
            newArtwork.images = arrayMove(newArtwork.images, from, to);
            let cover = artwork.cover;
            if (cover === from) {
                newArtwork.cover = to;
            } else if (from < to && cover > from && cover <= to) {
                newArtwork.cover = cover - 1;
            } else if (from > to && cover < from && cover >= to) {
                newArtwork.cover = cover + 1;
            }
            setArtwork(newArtwork);
        }
        setDragging(false);
    }

    return (
        <StyledArtworkEditor className="artwork-editor-page page">
            <div className="container">
                <div className="editor-row">
                    <div className="title">Title</div>
                    <input type="text" value={artwork.title} onChange={(e) => changeTitle(e.target.value)} />
                </div>
                <div className="editor-row">
                    <div className="title">Description</div>
                    <textarea
                        className="description"
                        value={artwork.description}
                        onChange={(e) => changeDescription(e.target.value)}
                    ></textarea>
                </div>
                <div className="editor-row">
                    <label className="file-uploader">
                        <span className="file-uploader-msg">
                            <p>Drag your file(s) here to begin</p>
                            <p>or click to browse</p>
                        </span>
                        <input type="file" accept=".jpg, .jpeg, .png" multiple onChange={uploadImages} />
                    </label>
                </div>
                <div className={cx("draggable-container", { dragging: dragging })}>
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                        onDragStart={onDrageStart}
                    >
                        <SortableContext
                            items={artwork.images.map((image) => image.path)}
                            strategy={rectSortingStrategy}
                        >
                            {artwork.images.map((image, index) => (
                                <ArtworkImageSortItem
                                    key={image.path}
                                    id={image.path}
                                    image={image}
                                    index={index}
                                    artwork={artwork}
                                    onDeleteImage={deleteImage}
                                    onSetAsCover={setAsCover}
                                />
                            ))}
                        </SortableContext>
                    </DndContext>
                </div>
                <div className="editor-row">
                    <button className="btn btn-primary btn-block" onClick={save}>
                        Save
                    </button>
                </div>
            </div>
        </StyledArtworkEditor>
    );
}
