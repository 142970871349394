import base64url from "base64url";
import { createContext, ReactElement, ReactNode, useReducer } from "react";
import Reducer from "./reducer";
import { ContextType, GlobalState } from "./types";
function getToken() {
    let token = localStorage.getJson("token");
    if (!token) {
        return undefined;
    }

    let parts = token.split(".");
    if (parts.length !== 3) {
        return undefined;
    }

    let payload = parts[1];
    return JSON.parse(base64url.decode(payload));
}

export function loadToken() {
    let payload = getToken();
    if (!payload) {
        return;
    }
    return {
        user: {
            id: payload.id,
            avatar: payload.avatar || "/static/user.svg",
            email: payload.email,
            name: payload.name,
        },
    };
}
/**
 * React Context-based Global Store with a reducer
 * and persistent saves to sessionStorage/localStorage
 **/
export function GlobalStore({ children }: { children: ReactNode }): ReactElement {
    const [globalState, dispatch] = useReducer(Reducer, initializeState());

    // useEffect(() => {
    //     /*
    //     populate either sessionStorage or localStorage data from globalState based on
    //     persistenceType and purge sessionStorage or localStorage when either is selected
    //     */
    //     const getPersistenceType = globalState.persistenceType;
    //     if (getPersistenceType === "sessionStorage") {
    //         sessionStorage.setItem("globalState", JSON.stringify(globalState));
    //         localStorage.removeItem("globalState");
    //     } else if (getPersistenceType === "localStorage") {
    //         localStorage.setItem("globalState", JSON.stringify(globalState));
    //         sessionStorage.removeItem("globalState");
    //     }
    // }, [globalState]);

    return <globalContext.Provider value={{ globalState, dispatch }}>{children}</globalContext.Provider>;
}

export const globalContext = createContext({} as ContextType);

export const initialState: GlobalState = {
    user: undefined,
    visitCount: undefined,
    posts: [],
    artworks: [],
    isUserAuthenticated: false,
    loggedUser: "",
    persistenceType: "sessionStorage",
    tags: [],
    // Posts
    hasMorePosts: true,
    editingFilter: false,
    loadingPosts: false,
    pageIndex: -1,
    pageSize: 20,
    searchParams: { sortOrder: { createdAt: -1 }, searchText: "", tagIds: [] },
};

function initializeState() {
    return { ...initialState, user: loadToken() };
    /*
    the order in which the the data is compared is very important;
    first try to populate the state from Storage if not return initialState
    */
    // const fromLocalStorage = JSON.parse(localStorage.getItem("globalState") as string);
    // const fromSessionStorage = JSON.parse(sessionStorage.getItem("globalState") as string);
    // return fromSessionStorage || fromLocalStorage || initialState;
}
