import { Link, useMatch } from "react-router-dom";
import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import { globalContext } from "../store";
import service from "../service";
import { TagPill } from "../components/TagPill";

const Page = styled.div`
    margin-top: var(--space-xxl);
    padding: var(--space-xl) 0;
`;
export function TagEditor() {
    const {
        dispatch: globalDispatch,
        globalState: { tags },
    } = useContext(globalContext);
    useEffect(() => {
        // On page load, fetch all the tags.
        service.getTags().then((tags) => {
            globalDispatch({ type: "SET_TAGS", payload: tags });
        });
    }, []);
    const [tagName, setTagName] = useState("");
    const add = () => {
        let name = tagName.trim();
        if (!name) {
            alert("Empty Tag!");
            return;
        }
        service.newTag(name).then(
            () => {
                setTagName("");
            },
            (e) => {
                alert(e.response.data);
            },
        );
    };
    return (
        <Page>
            <div className="body">
                <div className="container">
                    {tags.map((tag) => {
                        return <TagPill key={tag._id}>{tag.name}</TagPill>;
                    })}
                    <div className="editor-row">
                        <div className="name">Name</div>
                        <input type="text" value={tagName} onChange={(e) => setTagName(e.target.value)} />
                    </div>
                    <div className="editor-row">
                        <button className="btn btn-primary" onClick={add}>
                            Add
                        </button>
                    </div>
                </div>
            </div>
        </Page>
    );
}
