import styled from "styled-components";
export const TagPill = styled.div`
    text-decoration: none;
    margin: var(--space-xs);
    display: inline-block;
    padding: var(--space-sm) var(--space-md);
    border-radius: var(--space-md);
    background-color: var(--color-pill-background);
    color: var(--color-secondary-text);
    transition: background-color var(--anim-duration-short) ease-in-out, color var(--anim-duration-short) ease-in-out;
    cursor: pointer;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        background-color: var(--color-light-grey);
    }

    &.active {
        color: #fff;
        background-color: var(--color-active-pill-background);
    }
`;
