import { Link } from "react-router-dom";
import { Artwork } from "../types";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getImageUrl } from "../utils";
import { MetaSeparator } from "./MetaSeparator";

const ArtworkCover = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
    }

    .buttons {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 var(--space-sm);
        background-color: rgba(255, 255, 255, 0.5);
    }
`;

const ArtworkInfo = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8));
    color: #fff;
    opacity: 0;
    transition: opacity var(--anim-duration-short) ease-in-out;
    &:hover {
        opacity: 1;
    }
    .info-content {
        align-items: center;
        .title {
            padding: var(--space-md);
            font-size: 1.5em;
        }
        .meta {
            padding: var(--space-sm);
            margin: 0 var(--space-sm);
            color: var(--color-secondary-text);
            font-size: 0.8em;
        }
    }
`;

const ArtworkTileContainer = styled(Link)`
    background-color: var(--color-artwork-card-background);
    display: inline-block;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    width: 20em;
    height: 20em;
`;

export interface ArtworkTileProps {
    artwork: Artwork;
    className?: string;
}

export function ArtworkTile({ artwork, className }: ArtworkTileProps) {
    return (
        <ArtworkTileContainer to={`/artwork/${artwork._id}`} className={className}>
            {artwork.images.length > 0 && (
                <ArtworkCover>
                    <img src={getImageUrl(artwork.images[artwork.cover || 0].path)} alt="" />
                </ArtworkCover>
            )}
            <ArtworkInfo className="flex-hv-center">
                <div className="info-content flex-vertical">
                    <div className="title">{artwork.title}</div>
                    <div className="meta">
                        <span className="page-view">
                            <FontAwesomeIcon icon={["fas", "eye"]} />
                            &nbsp;{artwork.view || 0}
                        </span>
                        <MetaSeparator />
                        <span className="like-num">
                            <FontAwesomeIcon icon={["fas", "heart"]} />
                            &nbsp;{artwork.like}
                        </span>
                    </div>
                </div>
            </ArtworkInfo>
        </ArtworkTileContainer>
    );
}
