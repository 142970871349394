import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MetaItem } from "../types";
import { formatPostDate } from "../utils";
import { MetaSeparator } from "./MetaSeparator";

interface MetaProps {
    item: MetaItem;
}

export function Meta({ item }: MetaProps) {
    return (
        <>
            <span className="created-at meta-item">{formatPostDate(item.createdAt)}</span>
            <MetaSeparator />
            <span className="page-view meta-item">
                <FontAwesomeIcon icon="eye"></FontAwesomeIcon>&nbsp;
                {item.view || 0}
            </span>
            <MetaSeparator />
            <span className="like-num meta-item">
                <FontAwesomeIcon icon="heart"></FontAwesomeIcon>&nbsp;
                {item.like || 0}
            </span>
        </>
    );
}
