import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { Post } from "../types";
import { formatPost, highlightPost } from "../utils";
import cx from "classnames";

const Marked = styled.div`
    word-break: break-word;
    line-height: 1.5;

    h1 {
        font-size: 1.4em;
        counter-increment: h1;
        counter-reset: h2 0;
    }

    h1:before {
        content: counter(h1) ". ";
    }

    h2 {
        font-size: 1.3em;
        border: none;
        counter-increment: h2;
        counter-reset: h3 0;
    }

    h2:before {
        content: counter(h1) "." counter(h2) ". ";
    }

    h3 {
        font-size: 1.2em;
        border: none;
        counter-increment: h3;
        counter-reset: h4 0;
    }

    h3:before {
        content: counter(h1) "." counter(h2) "." counter(h3) ". ";
    }

    h4 {
        font-size: 1.1em;
        border: none;
        counter-increment: h4;
        counter-reset: h5 0;
    }

    h4:before {
        content: counter(h1) "." counter(h2) "." counter(h3) "." counter(h4) ". ";
    }

    h1,
    h2,
    h3,
    h4 {
        margin: var(--space-md) 0 var(--space-sm);
    }

    p {
        margin: var(--space-sm) 0;
    }

    blockquote {
        color: var(--color-secondary-text);
        border-left: var(--space-sm) solid var(--color-light-grey);
        /* background-color: fade(var(--color-light-grey), 50%); */
        padding: var(--space-md);
        margin: var(--space-sm) 0;
    }

    ol,
    ul {
        margin: var(--space-md) 0;
        padding-left: var(--space-lg);
    }

    ul {
        list-style: initial;
    }

    pre,
    code {
        font-family: inherit;
    }

    code:not(.hljs) {
        border-radius: var(--default-border-radius);
        padding: var(--space-xs) var(--space-sm);
        display: inline;
        margin: 0 var(--space-xs);
        color: #c7254e;
        background-color: var(--background-color);
    }

    pre {
        border-radius: var(--default-border-radius);
        color: var(--color-pre-text);
        margin: var(--space-md) 0;

        position: relative;
        overflow: hidden;
        white-space: pre;

        > code {
            display: block;
            overflow-x: auto;
            white-space: pre;
            padding: var(--space-xl);
            margin: 0;
            word-break: keep-all;
        }
    }

    code {
        font-family: Menlo, Monaco, Consolas, Courier New, monospace;
        font-size: 0.9em;
    }

    table {
        border-collapse: separate;
        border-radius: var(--default-border-radius);
        border: var(--space-xs) solid var(--color-border);

        tr:first-child {
            th,
            td {
                border: 0;
            }
        }

        th,
        td {
            padding: var(--space-xs) var(--space-sm);
            border-top: solid 1px var(--color-border);
        }
    }

    img,
    video {
        display: block;
        margin: var(--space-md) auto;
        max-width: 100%;
        border-radius: var(--default-border-radius);
        border: 0.3em solid var(--color-border);
    }

    a {
        color: var(--color-link);

        &:hover {
            text-decoration: underline;
        }
    }

    hr {
        height: var(--space-xs);
        background-color: var(--color-border);
        border: 0;
        margin: var(--space-md) 0;
    }
`;

export interface MarkedPostContentProps {
    post: Post;
    className?: string;
}

export function MarkedPostContent({ post, className }: MarkedPostContentProps) {
    const onPostRefChange = useCallback((node) => {
        if (node) {
            highlightPost(node as HTMLElement);
        }
    }, []);
    const postContent = useMemo(() => formatPost(post?.content), [post]);
    return (
        <Marked
            className={cx(className, "post-content")}
            ref={onPostRefChange}
            dangerouslySetInnerHTML={postContent}
        ></Marked>
    );
}
