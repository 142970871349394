import { useState } from "react";
import styled from "styled-components";
import service from "../service";
import cx from "classnames";
const ImageContainer = styled.div`
    display: inline-block;
    position: relative;
    width: 12em;
    height: 12em;
    overflow: hidden;
    .preview-image {
        width: 100%;
        height: 100%;
        object-fit: contain;

        &.local {
            opacity: 0.5;
        }
    }
`;

const VideoContainer = styled.div`
    display: inline-block;
    position: relative;
    width: 12em;
    height: 12em;
    overflow: hidden;
    .preview-video {
        width: 100%;
        height: 100%;
        object-fit: cover;

        &.local {
            opacity: 0.5;
        }
    }
`;

interface MediaItem {
    file: File;
    data?: string;
    path?: string;
}

export function Upload() {
    const [blobs, setBlobs] = useState({ images: [], videos: [] });
    const [images, setImages] = useState<MediaItem[]>([]);
    const [uploadedImages, setUpdatedImages] = useState([]);
    const [videos, setVideos] = useState<MediaItem[]>([]);
    const uploadItem = (file: File, setField: () => void, uploadFunc: (file: File) => Promise<boolean>) => {
        // let index = blobs[field].length;
        // blobs[field].push({ file: file, data: null, path: null });
        // let reader = new FileReader();
        // reader.onload = (e) => {
        //     blobs[field][index].data = e.target.result;
        // };
        // reader.readAsDataURL(file);
        // uploadFunc(file).then((uploaded) => {
        //     this[field][index].data = undefined;
        //     this[field][index].file = undefined;
        //     this[field][index] = Object.assign({}, this[field][index], uploaded);
        // });
    };
    const upload = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            Array.from(e.target.files).forEach((file) => {
                if (file.type.startsWith("video")) {
                    const index = videos.length;
                    setVideos((prev) => [...prev, { file }]);
                    const reader = new FileReader();
                    let data = null;
                    reader.onload = (e: ProgressEvent<FileReader>) => {
                        data = e.target?.result;
                        setVideos((prev) => {
                            prev[index].data = e.target?.result as string;
                            return prev;
                        });
                    };
                    reader.readAsDataURL(file);
                    service.uploadVideo(file).then((uploaded) => {
                        setVideos((prev) => {
                            prev[index] = uploaded;
                            return [...prev];
                        });
                    });
                } else {
                    const index = images.length;
                    setImages((prev) => [...prev, { file }]);
                    const reader = new FileReader();
                    let data = null;
                    reader.onload = (e: ProgressEvent<FileReader>) => {
                        data = e.target?.result;
                        setImages((prev) => {
                            prev[index].data = e.target?.result as string;
                            return [...prev];
                        });
                    };
                    reader.readAsDataURL(file);
                    service.uploadImage(file).then((uploaded) => {
                        setImages((prev) => {
                            prev[index] = uploaded;
                            return [...prev];
                        });
                    });
                }
            });
        }
    };
    return (
        <div className="upload-image-page page">
            <div className="body">
                <div className="container">
                    <div className="editor-row">
                        <label className="file-uploader">
                            <span className="file-uploader-msg">
                                <p>Drag your file(s) here to begin</p>
                                <p>or click to browse</p>
                            </span>
                            <input
                                type="file"
                                accept=".jpg, .jpeg, .png, .gif, .mp4"
                                multiple
                                onChange={(e) => upload(e)}
                            />
                        </label>
                    </div>
                    {images.map((img, index) => {
                        return (
                            <ImageContainer>
                                <img
                                    className={cx("preview-image", { local: img.data })}
                                    src={img.path || img.data}
                                    key={"image" + index}
                                />
                            </ImageContainer>
                        );
                    })}
                    {videos.map((video, index) => {
                        return (
                            <VideoContainer>
                                <video
                                    className={cx("preview-video", { local: video.data })}
                                    src={video.path || video.data || ""}
                                    controls
                                    key={"video" + index}
                                />
                            </VideoContainer>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
