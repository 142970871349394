import { RouterProvider, createBrowserRouter, Outlet, ScrollRestoration } from "react-router-dom";
import { Home } from "./views/Home";
import { Posts } from "./views/Posts";
import { Artworks } from "./views/Artworks";
import { ArtworkPage } from "./views/ArtworkPage";
import { ArtworkEditorPage } from "./views/ArtworkEditorPage";
import { PostPage } from "./views/Post";
import { SignPage } from "./views/SignPage";
import { WriterPage } from "./views/WriterPage";
import { LiveEditPage } from "./views/LiveEditPage";
import { NotFound } from "./views/NotFound";
import { TagEditor } from "./views/TagEditor";
import { SiteHeader } from "./components/SiteHeader";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
    faFilter,
    faTag,
    faSortAmountUp,
    faLongArrowAltUp,
    faLongArrowAltDown,
    faEye,
    faHeart,
    faChevronLeft,
    faChevronUp,
    faPlus,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";
import "flexboxgrid2/flexboxgrid2.min.css";
import "./App.scss";
import { GlobalStore, globalContext } from "./store/index";
import { useContext, useEffect } from "react";
import service from "./service";
import { GlobalAction } from "./store/reducer";
import { RoutePath } from "./utils";
import React from "react";
import { Upload } from "./views/Upload";
library.add(
    fab,
    faFilter,
    faTag,
    faSortAmountUp,
    faLongArrowAltUp,
    faLongArrowAltDown,
    faEye,
    faHeart,
    faChevronLeft,
    faChevronUp,
    faPlus,
    faTimes,
);

const router = createBrowserRouter([
    {
        element: <Layout />,
        children: [
            {
                path: "/",
                element: <Home />,
            },
            {
                path: "/posts",
                element: <Posts />,
            },
            {
                path: "/post/:postId",
                element: <PostPage />,
            },
            {
                path: "/artworks",
                element: <Artworks />,
            },
            {
                path: "/artwork/:artworkId",
                element: <ArtworkPage />,
            },
            {
                path: "/artwork-editor/:artworkId?",
                element: <ArtworkEditorPage />,
            },
            {
                path: "/sign/:tab",
                element: <SignPage />,
            },
            {
                path: "/writer/:postId?",
                element: <WriterPage />,
            },
            {
                path: `${RoutePath.LiveEdit}/:postId`,
                element: <LiveEditPage />,
            },
            {
                path: `/tag-editor`,
                element: <TagEditor />,
            },
            {
                path: `/upload`,
                element: <Upload />,
            },
            {
                path: "*",
                element: <NotFound />,
            },
        ],
    },
]);

function Layout() {
    return (
        <>
            <SiteHeader />
            <Outlet />
            <ScrollRestoration
                getKey={(location) => {
                    return location.pathname; // as long as the pathname matches, restore the scroll.
                }}
            />
        </>
    );
}

function AppInternal() {
    const { dispatch: globalDispatch } = useContext(globalContext);
    useEffect(() => {
        service.updatePageView("Home").then((pageView) => {
            globalDispatch({ type: GlobalAction.SET_VISIT_COUNT, payload: pageView });
        });
    }, [globalDispatch]);
    return (
        <div className="app">
            <RouterProvider router={router} />
        </div>
    );
}
function App() {
    return (
        <React.StrictMode>
            <GlobalStore>
                <AppInternal />
            </GlobalStore>
        </React.StrictMode>
    );
}

export default App;
