import { Dispatch } from "react";
import { Artwork, Post, PostTag } from "../types";
export interface SearchParams {
    searchText: string;
    sortOrder: { [key: string]: number };
    tagIds: string[];
}
export interface GlobalState {
    user?: {
        id: string;
        avatar: string;
        email: string;
        name: string;
    };
    visitCount?: number;
    isUserAuthenticated: boolean;
    loggedUser: string;
    persistenceType: string;
    tags: PostTag[];
    [key: string]: any;
    artworks: Artwork[];
    // Posts
    posts: Post[];
    hasMorePosts: boolean;
    pageIndex: number;
    pageSize: number;
    editingFilter: boolean;
    loadingPosts: boolean;
    searchParams: SearchParams;
}

export type ActionType = {
    type: string;
    payload?: any;
};

export type ContextType = {
    globalState: GlobalState;
    dispatch: Dispatch<ActionType>;
};

export enum RequestStatus {
    None = "none",
    Pending = "pending",
    Success = "success",
    Failed = "failed",
}
