import cx from "classnames";
import { useContext, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import service from "../service";
import { globalContext } from "../store";
import { GlobalAction } from "../store/reducer";

const Tab = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;
    overflow: hidden;
    .tab-buttons {
        display: flex;
        .tab-button {
            flex: 1;
            text-align: center;
            padding: var(--space-sm) var(--space-md);
            cursor: pointer;
            background-color: var(--color-tab-button-background);
            &.active {
                background-color: var(--color-tab-button-active-background);
            }
        }
    }
    .tab-content {
        display: none;
        padding: var(--space-md);
        &.active {
            display: block;
        }
    }
`;

const SignPageWraper = styled.div`
    .sign-panel {
        width: 15em;

        label {
            display: block;
            margin: 0.5em 0;

            input[type="text"],
            input[type="email"],
            input[type="password"] {
                display: block;
                margin: var(--space-xs) 0;
                width: 100%;
            }
        }

        .err {
            color: var(--color-error);
            font-size: 0.8em;
        }

        .btn {
            margin-top: var(--space-md);
            float: right;
        }
    }
`;

function getError(e: any) {
    return e.response ? e.response.data : e.message;
}

export function SignPage() {
    const { dispatch: globalDispatch } = useContext(globalContext);
    const { tab } = useParams<{ tab: string }>();
    const activeTabIndex = useMemo(() => (tab === "up" ? 1 : 0), [tab]);
    // const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [err, setErr] = useState("");

    const navigate = useNavigate();

    const [name, setName] = useState("");
    function changeTab(index: number) {
        const tab = index === 0 ? "in" : "up";
        navigate(tab, { replace: true });
    }
    function signIn() {
        setErr("");
        service.signIn(email, password).then(
            (res: any) => {
                globalDispatch({ type: GlobalAction.UPDATE_TOKEN, payload: res.data.token });
                navigate("/", { replace: true });
            },
            (e) => setErr(getError(e)),
        );
    }
    function signUp() {
        setErr("");
        service.signUp(name, email, password).then(
            (res) => {
                globalDispatch({ type: GlobalAction.UPDATE_TOKEN, payload: res.data.token });
                navigate("/", { replace: true });
            },
            (e) => setErr(getError(e)),
        );
    }
    return (
        <SignPageWraper className="sign-in-page page">
            <Tab className="card absolute-hv-center">
                <div className="tab-buttons">
                    <div className={cx("tab-button", { active: activeTabIndex === 0 })} onClick={() => changeTab(0)}>
                        Sign In
                    </div>
                    <div className={cx("tab-button", { active: activeTabIndex === 1 })} onClick={() => changeTab(1)}>
                        Sign Up
                    </div>
                </div>
                <form
                    className={cx("tab-content sign-panel", { active: activeTabIndex === 0 })}
                    onSubmit={(e) => e.preventDefault()}
                >
                    <label>
                        Email:
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </label>
                    <label>
                        Password:
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </label>
                    <div className="err">{err}</div>
                    <button className="btn btn-primary" onClick={signIn} disabled={!email.trim() || !password}>
                        Sign In
                    </button>
                </form>
                <form
                    className={cx("tab-content sign-panel", { active: activeTabIndex === 1 })}
                    onSubmit={(e) => e.preventDefault()}
                >
                    <label>
                        Name:
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                    </label>
                    <label>
                        Email:
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </label>
                    <label>
                        Password:
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </label>
                    <div className="err">{err}</div>
                    <button
                        className="btn btn-primary"
                        onClick={signUp}
                        disabled={!name.trim() || !email.trim() || !password}
                    >
                        Sign Up
                    </button>
                </form>
            </Tab>
        </SignPageWraper>
    );
}
