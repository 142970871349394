import React from "react";
import styled from "styled-components";

const ModalContainer = styled.div`
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        width: auto;
        margin: 0px auto;
        padding: 20px 30px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }

    .modal-header {
        font-size: 1.5em;
    }

    .modal-body {
        margin: 20px 0;
    }

    .modal-footer {
        text-align: right;
    }
`;
export interface ModalProps {
    header?: JSX.Element;
    body?: JSX.Element;
    footer?: JSX.Element;
    onCancel: () => void;
}
export function Modal({ onCancel, header, body, footer }: ModalProps) {
    return (
        <ModalContainer>
            <div className="modal-mask">
                <div className="modal-wrapper">
                    <div className="modal-container">
                        <div className="modal-header">{header}</div>

                        <div className="modal-body">{body}</div>

                        <div className="modal-footer">
                            {footer ? (
                                footer
                            ) : (
                                <button className="btn btn-default" onClick={onCancel}>
                                    Done
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </ModalContainer>
    );
}
