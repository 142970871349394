import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import styled from "styled-components";
import { useMeta } from "../hooks/meta";
import { globalContext } from "../store";
const SelfIntro = styled.div`
    margin-top: 6em;
    text-align: center;
    p {
        font-size: 4em;
    }
`;
const Footer = styled.footer`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    .page-view {
        color: var(--color-secondary-text);
        font-size: 0.8em;
        margin-bottom: var(--space-md);
    }
    .community {
        padding: var(--space-md) 0 var(--space-xl);
        a {
            font-size: 2em;
            margin: 0 var(--space-sm);
            transition: opacity var(--anim-duration-short) ease-in-out;
            color: inherit;
            opacity: 0.5;
            &:hover {
                opacity: 1;
            }
        }
    }
`;
export function Home() {
    const { globalState } = useContext(globalContext);

    useMeta({
        title: "Zhenglai Liu's Blog",
        description: "Zhenglai Liu's Blog",
        author: "Zhenglai Liu",
    });
    return (
        <div className="home-page page">
            <div className="body">
                <div className="container">
                    <SelfIntro>
                        <p>Hi, I'm Zhenglai Liu</p>
                    </SelfIntro>
                </div>
            </div>
            <Footer>
                <div className="container">
                    {globalState.visitCount !== undefined && (
                        <div className="page-view">{globalState.visitCount} visits</div>
                    )}
                </div>
                <div className="community container">
                    <a href="https://github.com/lzl124631x" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={["fab", "github"]} />
                    </a>

                    <a href="https://www.linkedin.com/in/richard-liu-894592a2" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={["fab", "linkedin"]} />
                    </a>

                    <a href="https://www.instagram.com/liuzhenglaichn" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={["fab", "instagram"]} />
                    </a>
                </div>
            </Footer>
        </div>
    );
}
