import { ReactNode } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Post } from "../types";
import { Meta } from "./Meta";
import { MetaSeparator } from "./MetaSeparator";
import { MetaWrapper } from "./MetaWrapper";

export interface PostMetaProps {
    post: Post;
}
const TagLink = styled(Link)`
    text-decoration: none;
`;

const PostMetaWrapper = styled(MetaWrapper)`
    padding: var(--space-md) 0 var(--space-sm);
    ${TagLink} {
        color: inherit;
    }
`;

function joinElements(elems: ReactNode[], separator: ReactNode) {
    return elems.reduce(
        (memo: ReactNode[], cur) => (memo.length === 0 ? [cur] : [...memo, separator, cur]),
        [] as ReactNode[]
    );
}

export function PostMeta({ post }: PostMetaProps) {
    return (
        <PostMetaWrapper>
            <Meta item={post} />
            {!!post.tags.length && (
                <>
                    <MetaSeparator />
                    <span className="meta-item">
                        {joinElements(
                            post.tags.map((tag) => {
                                return (
                                    <TagLink key={tag.name} to={`/tag/${tag._id}`}>
                                        {tag.name}
                                    </TagLink>
                                );
                            }),
                            ", "
                        )}
                    </span>
                </>
            )}
        </PostMetaWrapper>
    );
}
