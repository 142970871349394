import axios from "axios";
import moment from "moment";
import "./polyfill";
import { Post } from "./types";
import { SearchParams } from "./store/types";
import { isAlive, getLikeActionKey, getViewActionKey } from "./utils";
const backendApiUrl = `${process.env.REACT_APP_BACKEND_URL}/api`;

function sendPost(url: string, params?: any, config?: any) {
    return axios.post(backendApiUrl + url, params, config);
}

function authPost(url: string, params?: any, _config?: any) {
    let config = Object.assign({ headers: {} }, _config);
    config.headers.Authorization = "Bearer " + localStorage.getJson("token");
    return axios.post(backendApiUrl + url, params, config);
}

const service = {
    getPostsPaginated(searchParams: SearchParams, pageIndex: number, pageSize: number): Promise<Post[]> {
        return sendPost("/posts", {
            pageIndex,
            pageSize,
            ...searchParams,
        }).then((res) => {
            let posts = res.data;
            return posts;
        });
    },
    getTaggedPosts(tagId: string) {
        return sendPost("/tagged-posts", { tagId }).then((res) => {
            let posts = res.data;
            return posts;
        });
    },
    getPostsInternal() {
        return sendPost("/posts", { showUnpublished: true }).then((res) => {
            let posts = res.data;
            // store.setPosts(posts);
            return posts;
        });
    },
    getPost(postId: string) {
        return sendPost("/post", { postId }).then((res) => res.data);
    },
    newPost() {
        return authPost("/new-post").then((res) => {
            let post = res.data;
            return post;
        });
    },
    deletePost(postId: string) {
        return authPost("/delete-post", { postId });
    },
    updatePost(post: Post) {
        return authPost("/update-post", { post });
    },
    signIn(email: string, password: string) {
        return sendPost("/sign-in", { email, password });
    },
    signUp(name: string, email: string, password: string) {
        return sendPost("/sign-up", { name, email, password });
    },
    uploadAvatar(avatar: any) {
        let data = new FormData();
        data.append("avatar", avatar);
        return authPost("/upload-avatar", data, {
            headers: { "content-type": "multipart/form-data" },
        });
    },
    updateProfile() {
        return authPost("/update-profile", {
            // name: globalState.user.name,
        });
    },
    updatePageView(page: string, guid?: string) {
        let key = `pageview-${page}${guid !== undefined ? `-${guid}` : ""}`;
        if (isAlive(key)) {
            let val = localStorage.getJson(key);
            return Promise.resolve(val.pageView);
        }
        return sendPost("/page-view", { page, guid }).then((res) => {
            let pageView = res.data.pageView;
            localStorage.setJson(key, { pageView, time: moment() });
            return pageView;
        });
    },
    saveArtwork(artwork: any) {
        return authPost("/save-artwork", { artwork });
    },
    getArtworks() {
        return sendPost("/artworks").then((res) => {
            let artworks = res.data.artworks;
            return artworks;
        });
    },
    deleteArtwork(id: string) {
        return authPost("/delete-artwork", { id }).then(() => {
            // store.deleteArtwork(id);
        });
    },
    getArtwork(id: string) {
        return sendPost("/artwork", { id }).then((res) => {
            return res.data.artwork;
        });
    },
    uploadImage(image: any) {
        let data = new FormData();
        data.append("image", image);
        return authPost("/upload-image", data, {
            headers: { "content-type": "multipart/form-data" },
        }).then((res) => res.data.image);
    },
    uploadVideo(video: any) {
        let data = new FormData();
        data.append("video", video);
        return authPost("/upload-video", data, {
            headers: { "content-type": "multipart/form-data" },
        }).then((res) => res.data.video);
    },
    like(type: string, id: string) {
        let key = getLikeActionKey(type, id);
        if (isAlive(key)) return;
        localStorage.setJson(key, { time: moment() });
        sendPost("/like", { type, id });
    },
    view(type: string, id: string) {
        let key = getViewActionKey(type, id);
        if (isAlive(key)) return;
        localStorage.setJson(key, { time: moment() });
        sendPost("/view", { type, id });
    },
    togglePublish(post: Post) {
        return authPost("/set-published", {
            postId: post._id,
            published: !post.published,
        });
    },
    tagPost(postId: string, tagId: string) {
        return authPost("/tag-post", { postId, tagId });
    },
    untagPost(postId: string, tagId: string) {
        return authPost("/untag-post", { postId, tagId });
    },
    newTag(tag: string) {
        return authPost("/new-tag", { tag });
    },
    deleteTag(tagId: string) {
        return authPost("/delete-tag", { tagId });
    },
    getTags() {
        return sendPost("/tags").then((res) => {
            let tags = res.data.tags;
            return tags;
        });
    },
};

export default service;
