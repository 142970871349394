import { HashTable } from "../types";
import { forOwn } from "../utils";
import { useEffect } from "react";
import { isFunction } from "lodash";

const metaClass = "dynamic-meta";
function removeElement(el: Element) {
    el.parentNode?.removeChild(el);
}
function appendMeta(obj: HashTable<string>) {
    let headEl = document.getElementsByTagName("head")[0];
    let metaEl = document.createElement("meta");
    metaEl.className = metaClass;
    headEl.appendChild(metaEl);
    forOwn(obj, (val, key) => {
        metaEl.setAttribute(key, val);
    });
}

function appendTitle(title: string) {
    let headEl = document.getElementsByTagName("head")[0];
    let titleEl = document.createElement("title");
    headEl.appendChild(titleEl);
    titleEl.innerHTML = title;
}

function updateMeta(meta: HashTable<string>) {
    document.querySelectorAll(`meta.${metaClass}`).forEach(removeElement);
    document.querySelectorAll(`title`).forEach(removeElement);
    forOwn(meta, (val, key) => {
        switch (key) {
            case "title": {
                appendTitle(val);
                appendMeta({
                    property: "og:title",
                    content: val,
                });
                break;
            }
            case "description": {
                appendMeta({
                    name: key,
                    content: val,
                });
                appendMeta({
                    name: "og:description",
                    content: val,
                });
                break;
            }
            case "author": {
                appendMeta({
                    name: key,
                    content: val,
                });
                break;
            }
        }
    });
}

export const useMeta = (getMeta: HashTable<string> | (() => HashTable<string>)) => {
    useEffect(() => {
        updateMeta(isFunction(getMeta) ? getMeta() : getMeta);
    }, [getMeta]);
};
