import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import cx from "classnames";
import { MetaItem } from "../types";

const LikeButtonWrapper = styled.div`
    width: auto;
    margin: 0 auto var(--space-xxl);
    display: flex;
    flex-direction: column;
    align-items: center;

    &.liked {
        .like-num,
        .like-btn {
            color: var(--color-like-text);
            border-color: var(--color-like-text);
        }
    }

    .like-num {
        padding: var(--space-md) 0;
        color: var(--color-border);
    }

    .like-btn {
        font-size: 2em;
        border-radius: 100%;
        padding: 0;
        width: 2em;
        height: 2em;
        border: 1px solid var(--color-border);
        color: var(--color-border);
        box-shadow: none;
    }
`;

export interface LikeButtonProps {
    liked: boolean;
    item: MetaItem;
    like: () => void;
}
export function LikeButton({ liked, item, like }: LikeButtonProps) {
    return (
        <LikeButtonWrapper className={cx(liked && "liked")}>
            <span className="like-num">{item.like}</span>
            <button className="btn like-btn" onClick={like}>
                <FontAwesomeIcon icon="heart"></FontAwesomeIcon>
            </button>
        </LikeButtonWrapper>
    );
}
