import { useContext, useEffect, useState } from "react";
import { PostCard, PostCardContainer } from "../components/PostCard";
import { PostFilter } from "../components/PostFilter";
import service from "../service";
import styled from "styled-components";
import useDebounce from "../hooks/debounce";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { getScrollTop } from "../utils";
import { globalContext } from "../store";
import { useMeta } from "../hooks/meta";
import { GlobalAction } from "../store/reducer";

const PostList = styled.div`
    margin-top: var(--space-xl);
    .no-post-placeholder {
        font-size: 2em;
        color: var(--color-secondary-text);
        text-align: center;
        padding: var(--space-xl) 0;
    }

    .end-message {
        text-align: center;
        color: var(--color-secondary-text);
        font-variant: small-caps;
        margin: var(--space-lg) 0;
    }

    ${PostCardContainer} + ${PostCardContainer} {
        border-top: solid 1px var(--color-border);
    }

    @media only screen and (max-width: 767px) {
        padding: var(--space-md);
    }

    @media only screen and (min-width: 768px) {
        padding: var(--space-xl) var(--space-md);
    }
`;

const LoadingSpinner = styled.div`
    position: relative;
    width: 1em;
    height: 1em;
    display: inline-block;
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(359deg);
        }
    }
    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        margin-top: -50%;
        margin-left: -50%;
        border-radius: 50%;
        border: 0.05em solid var(--color-border);
        border-top-color: var(--color-brand);
        animation: spin 0.8s linear infinite;
    }
`;

const LoadingMessage = styled.div`
    align-items: center;
    color: var(--color-secondary-text);
    .loading-msg-title {
        font-size: 1.5em;
        padding-bottom: var(--space-md);
    }
    ${LoadingSpinner} {
        font-size: 2em;
    }
`;

export function Posts() {
    const {
        dispatch: globalDispatch,
        globalState: { editingFilter, loadingPosts, searchParams, hasMorePosts, pageIndex, pageSize, posts },
    } = useContext(globalContext);

    const getPosts = () => {
        if (loadingPosts || !hasMorePosts) {
            // BUG: if loading, we should still send new requests if the parameters changes.
            return;
        }
        globalDispatch({ type: GlobalAction.FETCHING_POSTS, payload: pageIndex + 1 });
        service.getPostsPaginated(searchParams, pageIndex + 1, pageSize).then((newPosts) => {
            globalDispatch({ type: GlobalAction.APPEND_POSTS, payload: newPosts });
        });
    };

    const debouncedSearchParams = useDebounce(searchParams, 1000);
    useEffect(() => {
        getPosts();
    }, [debouncedSearchParams]);

    const [scrolledToBottom, setScrolledToBottom] = useState(false);

    useScrollPosition(() => {
        let el = window.document.documentElement;
        setScrolledToBottom(getScrollTop() > el.scrollHeight - el.clientHeight * 1.5);
    }, [setScrolledToBottom]);

    useEffect(() => {
        if (scrolledToBottom) {
            getPosts();
        }
    }, [scrolledToBottom]);

    useEffect(() => {
        // On page load, fetch all the tags.
        service.getTags().then((tags) => {
            globalDispatch({ type: "SET_TAGS", payload: tags });
        });
    }, [globalDispatch]);

    function getTrailingText() {
        if (editingFilter || loadingPosts) {
            return (
                <LoadingMessage className="flex-vertical">
                    <div className="loading-msg-title">Loading...</div>
                    <LoadingSpinner />
                </LoadingMessage>
            );
        }
        if (!posts.length) {
            return <div className="no-post-placeholder">No Post</div>;
        }
        if (!hasMorePosts) {
            return <div className="end-message">The End</div>;
        }
        return null;
    }

    useMeta({
        title: "Posts",
        description: "Here is a full list of all my posts",
        author: "Zhenglai Liu",
    });

    return (
        <div className="posts-page page">
            <div className="body">
                <div className="container">
                    <div className="row">
                        <PostFilter
                            onSearchParamsChange={(newSearchParams) => {
                                globalDispatch({ type: GlobalAction.SEARCH_PARAMS_UPDATED, payload: newSearchParams });
                            }}
                            searchParams={searchParams}
                        ></PostFilter>
                        <PostList className="col-md-9 col-sm-12 col-xs-12">
                            {posts.map((post) => (
                                <PostCard key={post._id} post={post}></PostCard>
                            ))}
                            {getTrailingText()}
                        </PostList>
                    </div>
                </div>
            </div>
        </div>
    );
}
