import styled from "styled-components";
import { Artwork } from "../types";
import { Meta } from "./Meta";
import { MetaWrapper } from "./MetaWrapper";

export interface ArtworkMetaProps {
    artwork: Artwork;
}

const ArtworkMetaWrapper = styled(MetaWrapper)`
    margin: var(--space-md) 0;
`;

export function ArtworkMeta({ artwork }: ArtworkMetaProps) {
    return (
        <ArtworkMetaWrapper>
            <Meta item={artwork} />
        </ArtworkMetaWrapper>
    );
}
