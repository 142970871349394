import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { useContext, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PostEditor } from "../components/PostEditor";
import service from "../service";
import { globalContext } from "../store";
import { GlobalAction } from "../store/reducer";
import { Post } from "../types";
import { findPost } from "../utils";

const StyledWriterPage = styled.div`
    align-items: stretch;
    border-top: solid 1px var(--color-light-grey);
    margin-top: 3em;
    flex: auto;
    min-height: 0;
    overflow: auto;

    .btn {
        transition: background-color var(--anim-duration-short) var(--anim-easing);
        &:hover {
            background-color: var(--color-light-grey);
        }
    }
    .post-list-column {
        width: 15em;
        border-right: solid var(--space-xs) var(--color-light-grey);
        overflow-x: hidden;
        overflow-y: auto;

        .btn-action {
            padding: var(--space-md);
            border-bottom: solid 1px var(--color-light-grey);
        }

        .post-list {
            .post-item {
                position: relative;
                padding: var(--space-md);
                padding-left: 1.5em;
                box-shadow: inset 0 0 0 0 transparent;
                border-bottom: 1px solid var(--color-light-grey);
                cursor: pointer;
                transition: box-shadow var(--anim-duration-short) ease-in-out;

                &:not(.published) {
                    background-color: var(--color-post-item-not-published-background);
                }

                &.active {
                    box-shadow: inset var(--space-sm) 0 0 0 var(--color-brand);
                }

                .post-title {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    height: 1.5em;
                    font-size: 1em;
                    font-weight: normal;
                }

                &:hover {
                    .btn-delete-post {
                        transform: translateX(-100%);
                    }
                }
                .btn-delete-post {
                    position: absolute;
                    top: 0;
                    left: 100%;
                    display: block;
                    font-size: 0.8em;
                    height: 100%;
                    padding: var(--space-sm);
                    background-color: var(--color-red);
                    color: #fff;
                    transform: translateX(0);
                    transition: transform var(--anim-duration-short) var(--anim-easing);
                }
            }
        }
    }

    .post-editor-column {
        flex: 1;
    }
`;

const getNextActivePost = (posts: Post[], postId: string) => {
    const index = posts.findIndex((p) => p._id === postId);
    if (index + 1 < posts.length) {
        return posts[index + 1];
    } else if (index - 1 >= 0) {
        return posts[index - 1];
    } else {
        return null;
    }
};

export function WriterPage() {
    const {
        dispatch: globalDispatch,
        globalState: { posts },
    } = useContext(globalContext);
    const navigate = useNavigate();
    const { postId } = useParams<{ postId: string }>();
    const activePost = useMemo(() => (postId ? findPost(posts, postId) : undefined), [posts, postId]);
    function newPost() {
        service.newPost().then((post) => {
            globalDispatch({ type: GlobalAction.PREPEND_POST, payload: post });
            navToPost(post._id);
        });
    }
    function navToPost(postId: string, replace = false) {
        const url = `/writer/${postId}`;
        if (replace) {
            navigate(url, { replace: true });
        } else {
            navigate(url);
        }
    }
    function deletePost(deletePostId: string) {
        let r = window.confirm("Are you sure you want to delete this post?");
        if (!r) return;
        let nextActivePost = getNextActivePost(posts, deletePostId);
        service.deletePost(deletePostId).then(() => {
            globalDispatch({ type: GlobalAction.DELETE_POST, payload: deletePostId });
            navToPost(nextActivePost?._id || "");
        });
    }
    function onChangePost(newPost: Post) {
        globalDispatch({ type: GlobalAction.UPDATE_POST, payload: newPost });
    }
    function onSavePost() {
        return service.updatePost(activePost as Post);
    }
    useEffect(() => {
        service.getPostsInternal().then((posts) => {
            globalDispatch({ type: GlobalAction.SET_POSTS, payload: posts });
            if (!postId && posts.length) {
                // select the first post by default
                navToPost(posts[0]._id, true);
            }
        });
    }, []);
    return (
        <StyledWriterPage className="page flex-horizontal">
            <div className="post-list-column">
                <div className="btn btn-action btn-block" onClick={newPost}>
                    <FontAwesomeIcon icon="plus" />
                    &nbsp;New Post
                </div>
                <div className="post-list">
                    {posts.map((post) => {
                        return (
                            <div
                                className={cx("btn post-item btn-block", {
                                    active: post._id === postId,
                                    published: post.published,
                                })}
                                key={post._id}
                                onClick={() => navToPost(post._id)}
                            >
                                <div className="post-title">{post.title}</div>
                                <button className="btn btn-delete-post" onClick={() => deletePost(post._id)}>
                                    <FontAwesomeIcon icon="times" />
                                </button>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="post-editor-column flex-grow">
                {activePost && <PostEditor post={activePost} onChangePost={onChangePost} onSavePost={onSavePost} />}
            </div>
        </StyledWriterPage>
    );
}
