import { useContext, useEffect, useState } from "react";
import { globalContext } from "../store";
import { TagPill } from "./TagPill";
import { Modal } from "./Modal";
import { PostTag } from "../types";
import styled from "styled-components";
import service from "../service";
import { GlobalAction } from "../store/reducer";
import cx from "classnames";

const EditTagModalContainer = styled.div`
    .modal-container {
        width: 20em;
    }
`;

interface EditTagModalProps {
    isOpen: boolean;
    postId: string;
    postTags: PostTag[];
    onPostTagsChanged: (newTags: PostTag[]) => void;
    onCancel: () => void;
}
export function EditTagModal({ isOpen, onCancel, onPostTagsChanged, postId, postTags }: EditTagModalProps) {
    const { globalState, dispatch } = useContext(globalContext);
    const tags = globalState.tags;
    useEffect(() => {
        service.getTags().then((tags) => {
            dispatch({ type: GlobalAction.SET_TAGS, payload: tags });
        });
    }, []);
    const isTagActive = (tag: PostTag) => {
        return postTags.some((t) => t._id === tag._id);
    };
    const toggleTag = (tag: PostTag, on: boolean) => {
        if (on) {
            service.tagPost(postId, tag._id).then(() => {
                onPostTagsChanged([...postTags, tag]);
            });
        } else {
            service.untagPost(postId, tag._id).then(() => {
                const newPostTags = [...postTags];
                newPostTags.splice(
                    newPostTags.findIndex((t) => t._id === tag._id),
                    1,
                );
                onPostTagsChanged(newPostTags);
            });
        }
    };
    return (
        <EditTagModalContainer>
            {isOpen && (
                <Modal
                    header={<>Edit Tags</>}
                    body={
                        <>
                            {tags.map((tag) => {
                                const active = isTagActive(tag);
                                return (
                                    <TagPill
                                        key={tag._id}
                                        className={cx({ active })}
                                        onClick={() => toggleTag(tag, !active)}
                                    >
                                        {tag.name}
                                    </TagPill>
                                );
                            })}
                        </>
                    }
                    onCancel={onCancel}
                ></Modal>
            )}
        </EditTagModalContainer>
    );
}
